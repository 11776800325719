<template>
  <div class="pipeline-update-setting">
    <div class="header pb-3">
      <v-row class="">
        <v-col>
          <div class="flex" v-if="!initLoading">
            <div class="style-title-setting-global">
              <span
                class="bold-700 mr-2 title-hover cursor-pointer"
                @click="handelCancel($event)"
              >
                {{ $i18n.locale == 'fr' ? 'Etape' : 'Step' }}
                <v-icon class="color-crm">mdi-chevron-right</v-icon></span
              >
              <!-- Pipeline : -->
              <span
                class="color-crm font-sz-18 bold-500 font-text text-capitalize"
                >{{
                  selectedEtape && selectedEtape.name ? selectedEtape.name : ''
                }}</span
              >
            </div>
          </div></v-col
        >
        <v-col cols="" class="">
          <div class="message">
            <div v-if="getEtapesLoading && !initLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEtapesError" class="error-msg">
              <ul v-if="Array.isArray(getEtapesError)">
                <li v-for="(e, index) in getEtapesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEtapesError }}</span>
            </div>
          </div></v-col
        >
        <v-col>
          <v-btn
            class="btn-filter float-right"
            dark
            color="#5C2DD3"
            @click.prevent.stop="addBlockStage"
            :class="{ 'custom-disabled-btn': getEtapesLoading }"
          >
            <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
            {{
              $i18n.locale == 'fr'
                ? 'Nouvelle catégorie de stade'
                : 'New stage category'
            }}
          </v-btn></v-col
        >
      </v-row>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="content mt-3" v-else>
      <div class="loader-content-projet" v-if="initLoading">
        <v-progress-circular
          indeterminate
          class="ml-3"
          color="#5C2DD3"
        ></v-progress-circular>
      </div>
      <div
        class="card-scene"
        ref="cardScene"
        v-if="
          !initLoading &&
            selectedEtape &&
            selectedEtape.stages &&
            selectedEtape.stages.length
        "
      >
        <Container
          orientation="horizontal"
          @drop="onColumnDrop"
          drag-handle-selector=".column-drag-handle"
          :drop-placeholder="upperDropPlaceholderOptions"
        >
          <Draggable v-for="stage in selectedEtape.stages" :key="stage.id">
            <div class="block-statut">
              <div class="card-column-header">
                <div class="input-title-stage">
                  <span class="numero-category-stade">
                    {{ selectedEtape.index }}.{{ stage.order + 1 }}
                  </span>
                  <v-text-field
                    dense
                    :placeholder="
                      $i18n.locale === 'fr'
                        ? 'Nom catégorie du stade'
                        : 'Stage category name'
                    "
                    @change="updateNameStage($event, stage)"
                    class="input-category-stade"
                    :class="{
                      'left-sup-10':
                        stage && stage.order && stage.order + 1 >= 10
                    }"
                    :title="
                      $i18n.locale === 'fr'
                        ? 'Modifier catégorie du stade'
                        : 'Modify stage category'
                    "
                    :value="stage.name"
                    v-model="stage.name"
                    outlined
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                  >
                    <template #prepend>
                      <v-btn
                        icon
                        class="cursor-move mt-0"
                        :class="{
                          'column-drag-handle':
                            selectedEtape &&
                            selectedEtape.stages &&
                            selectedEtape.stages.length > 1
                        }"
                      >
                        <v-icon>mdi-drag-horizontal-variant</v-icon>
                      </v-btn>
                    </template>
                    <template #append-outer>
                      <v-icon
                        @click.prevent.stop="tryDeleteStage(stage)"
                        :title="
                          $i18n.locale == 'fr'
                            ? 'Supprimer catégorie du stade'
                            : 'Delete stage category'
                        "
                        >mdi-close</v-icon
                      >
                    </template>
                  </v-text-field>
                </div>
              </div>
              <div
                class="container-ss"
                :class="{
                  'not-scroll':
                    stage && (!stage.subStages || !stage.subStages.length)
                }"
              >
                <Container
                  group-name="col"
                  @drop="onCardDrop($event, stage)"
                  drag-handle-selector=".ss-column-drag-handle"
                  :drop-placeholder="dropPlaceholderOptions"
                  :lock-axis="'y'"
                >
                  <Draggable
                    v-for="subStage in stage.subStages"
                    :key="subStage.id"
                  >
                    <div class="content-ss">
                      <div class="input-title-SS mt-1">
                        <span class="numero-stade">
                          {{ selectedEtape.index }}.{{ stage.order + 1 }}.{{
                            subStage.order + 1
                          }}
                        </span>
                        <v-text-field
                          @change="updateNameSousStage($event, subStage)"
                          :title="
                            $i18n.locale === 'fr'
                              ? 'Modifier nom du stade'
                              : 'Modify stage name'
                          "
                          :value="subStage.name"
                          :placeholder="
                            $i18n.locale === 'fr'
                              ? 'Nom du stade'
                              : 'Stage name'
                          "
                          dense
                          outlined
                          class="input-stade"
                          :class="{
                            'left-sup-10': selectedEtape.index >= 10
                          }"
                          color="#5C2DD3"
                          item-color="#5C2DD3"
                        >
                          <template #prepend>
                            <v-btn
                              icon
                              class="cursor-move mt-0"
                              :class="{
                                'ss-column-drag-handle':
                                  stage &&
                                  stage.subStages &&
                                  stage.subStages.length > 1
                              }"
                            >
                              <v-icon>mdi-drag-horizontal-variant</v-icon>
                            </v-btn>
                          </template>
                          <template #append-outer>
                            <v-icon
                              :title="
                                $i18n.locale == 'fr'
                                  ? 'Supprimer un stade'
                                  : 'Delete stage'
                              "
                              @click.prevent.stop="
                                tryDeleteSubStage(stage, subStage)
                              "
                              >mdi-close</v-icon
                            >
                          </template>
                        </v-text-field>
                      </div>
                      <div class="flex center mt-2">
                        <div class="flex center select-ss mr-2">
                          <v-menu
                            ref="menuColorStade"
                            :content-class="
                              'menu-color-stade-' +
                                stage.order +
                                '-' +
                                subStage.order
                            "
                            offset-y
                            max-width="10% !important"
                            :close-on-content-click="true"
                            :close-on-click="true"
                            min-width="10% !important"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                                class="flex-center select-color"
                              >
                                <v-btn
                                  :color="
                                    subStage.color ? subStage.color : '#5C2DD3'
                                  "
                                  dark
                                  small
                                  class="small-btn"
                                >
                                </v-btn>
                                <v-icon>mdi-menu-down</v-icon>
                              </div>
                            </template>
                            <v-list>
                              <v-list-item
                                link
                                v-for="(item, index) in [
                                  { text: '', value: null },
                                  {
                                    text:
                                      $i18n.locale === 'fr' ? 'Vert' : 'Green',
                                    value: 'green'
                                  },
                                  {
                                    text:
                                      $i18n.locale === 'fr' ? 'Rouge' : 'Red',
                                    value: 'red'
                                  },
                                  {
                                    text:
                                      $i18n.locale === 'fr'
                                        ? 'Orange'
                                        : 'Orange',
                                    value: 'orange'
                                  },
                                  {
                                    text:
                                      $i18n.locale === 'fr'
                                        ? 'Jaune'
                                        : 'Yellow',
                                    value: 'yellow'
                                  }
                                ]"
                                :key="index"
                                @click.prevent.stop="
                                  handleColorChange(item, subStage)
                                "
                              >
                                <v-list-item-title>{{
                                  item.text
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <v-select
                            v-model="subStage.status"
                            @change="handleStatusChange(subStage)"
                            :label="$t('statut')"
                            dense
                            :no-data-text="
                              $t('noDataOptionMsg', { option: $t('statut') })
                            "
                            item-text="text"
                            item-value="value"
                            :items="options"
                            outlined
                            :persistent-placeholder="true"
                            :menu-props="{ bottom: true, offsetY: true }"
                            color="#5C2DD3"
                            item-color="#5C2DD3"
                          ></v-select>
                        </div>
                      </div>
                      <!-- v-if="selectedEtape.id == 66" -->
                      <div class="mt-3 select-ss">
                        <div class="flex mb-2">
                          <v-select
                            v-model="subStage.sms_template_id"
                            @change="handletemplateSmsChange($event, subStage)"
                            :label="
                              $i18n.locale === 'fr'
                                ? 'Envoie d\'un SMS'
                                : 'Send an SMS'
                            "
                            dense
                            :no-data-text="
                              $t('noDataOptionMsg', { option: 'sms' })
                            "
                            item-text="title"
                            item-value="id"
                            :items="getAllTemplateSms"
                            outlined
                            :persistent-placeholder="true"
                            clearable
                            :menu-props="{ bottom: true, offsetY: true }"
                            color="#5C2DD3"
                            item-color="#5C2DD3"
                            class="mr-2"
                          ></v-select>
                        </div>
                        <div class="flex">
                          <v-select
                            v-model="subStage.email_template_id"
                            @change="
                              handletemplateEmailChange($event, subStage)
                            "
                            :label="
                              $i18n.locale === 'fr'
                                ? 'Envoie d\'un E-mail'
                                : 'Send an e-mail'
                            "
                            dense
                            :no-data-text="
                              $t('noDataOptionMsg', { option: 'e-mail' })
                            "
                            item-text="title"
                            item-value="id"
                            :items="getAllTemplateEmail"
                            outlined
                            :persistent-placeholder="true"
                            clearable
                            :menu-props="{ bottom: true, offsetY: true }"
                            color="#5C2DD3"
                            item-color="#5C2DD3"
                            class="mr-2"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </Draggable>
                </Container>
                <div
                  class="btn-add-SS mb-2"
                  :class="{
                    'btn-h-flex': !stage.subStages || !stage.subStages.length
                  }"
                >
                  <v-btn
                    :disabled="getEtapesLoading"
                    @click.prevent.stop="addSousStage(stage)"
                    class="btn-filter small-btn"
                    small
                    outlined
                    color="#5C2DD3"
                    :title="
                      $i18n.locale === 'fr' ? 'Ajouter un stade' : 'Add stage'
                    "
                  >
                    <v-icon class="sz-icon-plus">mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
      </div>
    </div>
    <v-dialog
      v-model="deleteSubStageModal"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale == 'fr' ? 'Supprimer un stade' : 'Delete stage' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteSubStageModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="deleteSubStageModal">
            <template>
              <p class="mb-3">
                {{
                  $i18n.locale == 'fr'
                    ? 'Pour supprimer le stade '
                    : 'To delete the stage '
                }}
                <strong class="text-capitalize">
                  {{
                    subStageToDelete && subStageToDelete.name
                      ? subStageToDelete.name
                      : ''
                  }}
                </strong>

                {{
                  $i18n.locale == 'fr'
                    ? ', les projets qui lui sont associés seront également supprimés.'
                    : ', the projects associated with it will also be deleted.'
                }}
              </p>
            </template>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getEtapesLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEtapesError" class="error-msg">
              <ul v-if="Array.isArray(getEtapesError)">
                <li v-for="(e, index) in getEtapesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEtapesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="deleteSubStage"
            :loading="getEtapesLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteSubStageModal')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteStageModal" max-width="700" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? ' Supprimer la catégorie du stade'
                : 'Delete stage category'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('deleteStageModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="mb-3">
            {{
              $t('msgdeleted', {
                msg:
                  $i18n.locale === 'fr'
                    ? 'la catégorie du stade :'
                    : 'the stage category :'
              })
            }}
            <strong class="text-capitalize">
              {{ stageToDelete ? stageToDelete.name : '' }}
            </strong>
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getEtapesLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEtapesError" class="error-msg">
              <ul v-if="Array.isArray(getEtapesError)">
                <li v-for="(e, index) in getEtapesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEtapesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="deleteStage"
            :loading="getEtapesLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('deleteStageModal')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      selectedEtape: null,
      etapeName: null,
      etapeType: null,
      upperDropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      options: [
        { value: 'open', text: this.$i18n.locale === 'fr' ? 'Ouvert' : 'Open' },
        { value: 'lost', text: this.$i18n.locale === 'fr' ? 'Perdu' : 'Lost' },
        { value: 'won', text: this.$i18n.locale === 'fr' ? 'Conclu' : 'Won' }
      ],
      stageToDelete: null,
      selectedStage: null,
      subStageToDelete: null,
      substages: [],
      error: null,
      initLoading: true,
      deleteStageModal: false,
      deleteSubStageModal: false
    }
  },

  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchEtapes',
      'updateEtape',
      'addEtapesStages',
      'deleteEtapeStages',
      'updateEtapesStages',
      'addEtapeSubStages',
      'deleteEtapeSousStages',
      'updateEtapeSubStages',
      'fetchAllSmsTemplates',
      'fetchAllEmailTemplates',
      'fetchEtapeStage',
      'fetchEtapeSubstage',
      'resetErrorEtapes'
    ]),
    handlOpenIdPixel(subStage) {
      subStage.collapse = !subStage.collapse
    },
    handleStatusChange(subStage) {
      this.updateEtapeSubStages({
        subStage: subStage,
        data: { status: subStage.status }
      })
    },
    async handleColorChange(color, subStage) {
      subStage.color = color.value
      const response = await this.updateEtapeSubStages({
        subStage: subStage,
        data: { color: subStage.color }
      })
      if (response) {
        if (this.$refs.menuColorStade && this.$refs.menuColorStade.length) {
          this.$refs.menuColorStade.forEach(item => (item.isActive = false))
        }
      }
    },
    async modifierEtape() {
      if (this.$refs.updateEtapeChamp.validate()) {
        if (
          this.etapeName == this.selectedEtape.name &&
          this.etapeType == this.selectedEtape.type.id
        ) {
          return
        }
        const data = {}
        if (this.etapeName != this.selectedEtape.name) {
          data.name = this.etapeName
        }
        if (this.etapeType != this.selectedEtape.type.id) {
          data.type = this.etapeType
        }
        await this.updateEtape({
          etapes: this.selectedEtape,
          data: data
        })
        this.refreshStep()
      }
    },
    async addBlockStage() {
      let count =
        this.selectedEtape &&
        this.selectedEtape.stages &&
        this.selectedEtape.stages.length
          ? this.selectedEtape.stages.length
          : 0
      const stage = {
        name: `catégorie du stade ${count}`,
        order: count
      }
      await this.addEtapesStages({
        etape: this.selectedEtape,
        stage: stage
      })
      this.$nextTick(() => {
        this.$refs.cardScene.scrollLeft = this.$refs.cardScene.scrollWidth
      })
    },
    updateNameStage(e, stage) {
      this.updateEtapesStages({
        stage: stage,
        data: {
          name: e ? e : `catégorie du stade ${stage.order + 1}`
        }
      })
      setTimeout(() => {
        this.resetErrorEtapes()
      }, 500)
    },
    envoyerSmsSubstage(e, subStage) {
      this.updateEtapeSubStages({
        subStage: subStage,
        data: { has_sms: e ? 1 : 0 }
      })
    },
    async handletemplateSmsChange(e, subStage) {
      const response = await this.updateEtapeSubStages({
        subStage: subStage,
        data: { sms_template_id: e }
      })
      if (response) {
        this.envoyerSmsSubstage(e, subStage)
      }
    },

    envoyerEmailSubstage(e, subStage) {
      this.updateEtapeSubStages({
        subStage: subStage,
        data: { has_email: e ? 1 : 0 }
      })
    },
    async handletemplateEmailChange(e, subStage) {
      const response = await this.updateEtapeSubStages({
        subStage: subStage,
        data: { email_template_id: e }
      })
      if (response) {
        this.envoyerEmailSubstage(e, subStage)
      }
    },
    tryDeleteStage(stage) {
      this.resetErrorEtapes()
      this.stageToDelete = stage
      this.deleteStageModal = true
    },
    async deleteStage() {
      const response = await this.deleteEtapeStages({
        etape: this.selectedEtape,
        stage: this.stageToDelete
      })
      if (response) {
        this.closeDialog('deleteStageModal')
      }
    },
    tryDeleteSubStage(stage, subStage) {
      this.selectedStage = stage
      this.subStageToDelete = subStage
      this.deleteSubStageModal = true
    },
    async deleteSubStage() {
      const response = await this.deleteEtapeSousStages({
        csubStage: this.subStageToDelete,
        cstage: this.selectedStage
      })
      if (response) {
        this.closeDialog('deleteSubStageModal')
      }
    },

    closeDialog(ref) {
      this[ref] = false
      this.resetModal()
      this.resetErrorEtapes()
    },

    resetModal() {
      this.stageToDelete = null
      this.selectedStage = null
      this.subStageToDelete = null
      this.substages = []
      this.resetErrorEtapes()
      this.error = null
    },

    addSousStage(stage) {
      let count = stage.subStages.length
      const data = {
        name: 'Nouveau stade ' + count,
        order: count,
        id_cstage: stage.id,
        status: 'open'
      }
      this.addEtapeSubStages({ stage, data: data })
    },

    updateNameSousStage(e, subStage) {
      this.updateEtapeSubStages({
        subStage,
        data: {
          name: e ? e : `Nouveau stade ${subStage.order + 1}`
        }
      })
    },
    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      if (this.selectedEtape && this.selectedEtape.stages) {
        this.selectedEtape.stages = this.applyDrag(
          this.selectedEtape.stages,
          dropResult
        )
        this.updateEtapesStages({
          stage: this.selectedEtape.stages[addedIndex],
          data: {
            order: addedIndex
          }
        })
      }
    },

    onCardDrop(dropResult, stage) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }

      stage.subStages = this.applyDrag(stage.subStages, dropResult)

      this.updateEtapeSubStages({
        subStage: stage.subStages[addedIndex],
        data: { order: addedIndex }
      })
    },
    handelCancel() {
      this.$router.push('/setting/pipeline')
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    changeSubstage(stage) {
      this.substages = []
      for (let index = 0; index < stage.subStages.length; index++) {
        const subStages = stage.subStages
        if (subStages && subStages.length) {
          this.substages = subStages.filter(s => {
            return s.id != this.subStageToDelete.id
          })
        }
      }
    },
    async refreshStep() {
      if (this.selectedEtape) {
        await this.fetchEtapeStage({
          step_id: this.selectedEtape.id,
          etape: this.selectedEtape
        }).then(() => {
          if (
            this.selectedEtape &&
            this.selectedEtape.stages &&
            this.selectedEtape.stages.length
          ) {
            for (
              let index = 0;
              index < this.selectedEtape.stages.length;
              index++
            ) {
              this.fetchEtapeSubstage({
                cstage_id: this.selectedEtape.stages[index].id,
                stage: this.selectedEtape.stages[index]
              })
            }
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getEtapes',
      'getProjectsTypes',
      'getEtapesLoading',
      'getEtapesError',
      'getAllTemplateSms',
      'getAllTemplateEmail'
    ])
  },
  filters: {
    formatStatus(value, list) {
      if (!value || !list) {
        return null
      }
      return list.find(e => e.id == value)
    }
  },
  async mounted() {
    this.initLoading = true
    if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
      await this.fetchProjetsTypes()
    }
    if (
      (!this.getEtapes || !this.getEtapes.length) &&
      localStorage.getItem('typeProjetStep')
    ) {
      await this.fetchEtapes({
        type_id: localStorage.getItem('typeProjetStep')
      })
    }
    if (
      this.getEtapes &&
      this.getEtapes.length &&
      this.$route &&
      this.$route.params &&
      this.$route.params.id
    ) {
      const etape = this.getEtapes.filter(
        etape => etape.id == this.$route.params.id
      )
      const search = etape.length > 0 ? etape[0] : null
      if (!search) {
        this.$router.push('/setting/pipeline')
      } else {
        this.selectedEtape = search
        this.etapeName = search.name
        this.etapeType = search.type
      }
      await this.refreshStep()
    }
    this.fetchAllSmsTemplates()
    this.fetchAllEmailTemplates()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.pipeline-update-setting {
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    flex-wrap: nowrap;
    .titleG {
      display: flex;
      align-items: center;
    }
    .titre {
      margin-left: 10px;
    }
  }
  .button {
    .addstage {
      font-size: 15px;
    }
  }
  .title-hover {
    &:hover {
      text-decoration: underline;
    }
  }
  .custom-disabled-btn {
    pointer-events: none; /* Désactive les événements de clic */
    opacity: 0.5; /* Opacité réduite pour indiquer le bouton désactivé */
  }
  .message {
    height: 25px;
  }
  .select-color {
    margin-right: 5px;
  }
  .w-100px {
    width: 110px;
  }
  .btn-add-SS {
    .btn-ss {
      border-radius: 50px;
    }
    text-align: center;
    padding: 5px;
    &.btn-h-flex {
      height: 35vh;
      align-items: center;
      display: flex;
      justify-content: space-evenly;
    }
  }
  .fleche {
    padding-bottom: 8px;
    font-size: 22px;
  }
  .card-scene {
    max-height: calc(100vh - 220px) !important;
    height: calc(100vh - 220px) !important;
    margin-bottom: 0;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .card-scene::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .card-scene::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .card-scene::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: black;
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    box-shadow: none;
    background-color: transparent;
    color: #7b8785;
    background-color: transparent;
    border-color: transparent;
  }
  .block-statut {
    width: 44vh;
  }
  .select-color {
    border: 1px solid #d8d8d8;
    padding: 5px 4px;
    margin-top: -3px;
    background-color: #f6f6f6;
    border-radius: 4px;
    margin-right: 16px;
  }
}
</style>
<style lang="scss" scoped>
.icon-id-pixel {
  color: #7b8785;
  background-color: transparent;
  border-color: transparent;
  box-shadow: unset;
  margin-right: 3px;
}
.icon-pixel {
  //   font-size: 64%;
  //   font-weight: 700;
  //   line-height: 0;
  //   white-space: nowrap;
  //   border-radius: 0.25rem;
  //   margin: 4px;
  //   padding: 4px;
  //   margin-right: 14px;
  color: #28a745;
  &:hover {
    color: #28a74585;
  }
  &.error-color {
    color: #ff6961;
    &:hover {
      color: #ff6961a3;
    }
  }
}

.icon-stage,
.btn-s-stage {
  .btn-left,
  .color-btn-ss {
    color: #495057ab;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    margin-right: 3px;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    color: #495057ab;
    background-color: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.075);
  }
}

.card-column-header {
  position: relative;
  width: 100%;
  padding: 9px;
  background-color: #f6f6f6;
  border-radius: 6px;
  margin-bottom: 13px;
}
.smooth-dnd-container.horizontal {
  display: flex;
}
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  // position: relative;
  // overflow: visible;
  // margin-top: 5px;
  // border: 1px solid #5C2DD33d;
  // background-color: #5C2DD317;

  // padding: 4px;
  // width: 100%;
  // width: 70%;
  padding: 5px 13px;
  background-color: #f6f6f6;
  border-radius: 6px;
}
.content-ss {
  position: relative;
  overflow: visible;
  margin-top: 5px;
  background-color: #ffffff;
  padding: 10px 14px;
  width: 100%;
  border-radius: 6px;
}
// .smooth-dnd-container {
//   margin: 3px;
// }
.smooth-dnd-container {
  border-radius: 4px;
  // position: relative;
  // min-height: 30px;
  // min-width: 255px;
}
.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
  //   border: 1px solid #6c757d4d;
  margin-right: 8px;
  width: 44vh;
  // border: 1px solid #7f7d8457;
  // background-color: #f6f6f6;
  // border-radius: 4px;
}
</style>
<style lang="scss">
.pipeline-update-setting {
  .container-ss {
    max-height: calc(100vh - 304px) !important;
    height: calc(100vh - 304px) !important;
    margin-bottom: 0;
    overflow: auto;
    padding: 0px 0px;
    cursor: pointer;
    background-color: #f6f6f6;
    &.not-scroll {
      overflow: hidden;
    }
  }
  .container-ss::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .container-ss::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .container-ss::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: black;
  }
  // .select-statut {
  //   .v-input__slot {
  //     width: 95% !important;
  //   }
  // }
  .input-title-stage,
  .input-title-SS {
    .input-category-stade {
      input {
        padding-left: 27px;
      }
      &.left-sup-10 {
        input {
          padding-left: 37px;
        }
      }
    }
    .input-stade {
      &.left-sup-10 {
        input {
          padding-left: 37px;
        }
      }
      input {
        padding-left: 27px;
      }
    }
    .numero-stade {
      font-weight: 700;
      position: absolute;
      left: 62px;
      top: 23px;
      font-size: 14px;
    }
    .numero-category-stade {
      font-weight: 700;
      position: absolute;
      left: 62px;
      top: 18px;
      font-size: 14px;
    }
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none;
    }
    .v-label {
      font-size: 15px !important;
      color: black !important;
    }
    .v-input__prepend-outer {
      margin-top: 0px !important;
    }
  }
  .select-ss {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none;
    }
  }
}
</style>
